export const API_ENDPOINTS= {
  customerDetails: "customer-details",
  phoneNumber:'phone-number',
  quoteDetails:'quote-details',
  quoteFlowDataMapping:'quote-data-mapping',
  city:'city',
  make:'make',
  model:'model',
  varient:'varient',
  nationality:'nationality',
  color:'color',
  createQuote:'create-quote',
  getLastWorkflow:'get-last-workflow',
  customerDetail: "customer-detail",
  product:'product',
  customerVehicleDetailForCreateQuote:'customer-vehicle-details',
  selectedProduct:'selected-product',
  updateWorkFLow:'update-workflow',
  updateQuote:'update',
  getAllCustomerVehicleDetailsForUpdate:'all-customer-vehicle-details-for-update',
  uploadDocuments:'upload-document',
  getDocumentMaster:'get-document-master',
  quoteSummary:'quote-summary',
  payment:'payment',
  verifyOrder:'verify-order-status',
  verifyDocument:'verify-document',
  policy:'policy',
  paymentStatus:'payment-status',
  verifyPaymentStatus:'verify-payment-status',
  generateToken:'generate-token',
  salik:'salik',
  signIn:'sign-in',
  kyc:'kyc',
  verifyOtp:'verify-otp',
  updateEmail:'update-email',
  forgotPassword:'forgot-password',
  resetPassword:'change-password',
  xaData:'xaData',
  getSalikCustomerVehicleDetails:'get-salik-customer-vehicle-details',
  updatefailedQuoteStatusForbackButton:'update-quote-status-backbutton',
  lisOfPolicy:'list-of-policy',
  generateOtp: "generate-otp",
  updateViewQuote:'update-view-quote',
  claims: 'claims',
  data:'data'
}
