import { Component, Input, OnInit } from '@angular/core';
import { SessionService } from '../../services/session-service/session.service';
import { CURRENTWORKFLOW, PREVIOUSWORKFLOW, QUOTEENQUIRYID, TOKEN, VERSION } from '../../interface/master-interface';
import { Router } from '@angular/router';
import { flowBasedRouting } from '../../mock-data';
import { CreateQuoteService } from '../../services/create-quote/create-quote.service';
import { QuoteSummaryService } from '../../services/quote-summary/quote-summary.service';
import { CUSTOMERUUVID } from '../../session-variables';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

interface vehicleDetails {
  vehicleName: string;
  vehicleValue: number;
  policyStartDate: string
}

@Component({
  selector: 'app-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss']
})
export class HeaderV2Component implements OnInit{
  @Input() customerVehicleDetails?: vehicleDetails
  @Input() backButton: boolean = false
  @Input() hideShareIcon:boolean = false
  @Input() hideProfileIcon:boolean = false
  @Input() loading: number = 1
  @Input() hideWorkflow: boolean = false
  flowdata = flowBasedRouting;
  quoteEnquiryId: number;
  previousWorkFlow: string;
  currentWorkflow: string;
  version: number;
  token: string;
  customerUuvid: string;
  profileDropdownStatus: boolean = false
  @Input() pageName:string =""
  excludeWorkflow = ['error-page','login-page','forgot-password','update-email','kyc','otp']
  showThisNavbar: boolean = false
  loadingChecker = [
    {
      id:1,
      title:'Quote'
    },
    {
      id:2,
      title:'Details'
    },
    {
      id:3,
      title:'Review'
    },
    {
      id:4,
      title:'Payment'
    },
    {
      id:5,
      title:'Documents'
    },
  ]
  constructor(
    private sessionStorage: SessionService,
    private route: Router,
    private createQuoteService: CreateQuoteService,
    private quoteSumaryService: QuoteSummaryService,
    private router: Router,
    private clipboardService: ClipboardService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }
  ngOnInit(): void {
    this.token = this.sessionStorage?.get(TOKEN);
    this.customerUuvid = this.sessionStorage.get(CUSTOMERUUVID);
    this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
    if(this.previousWorkFlow == 'url_generated'){
      this.backButton = false
    }
    this.showThisNavbar = this.excludeWorkflow.includes(this.pageName)
  }

   async redirecToPreviousPage() {
    try {
      let isQuoteFailedBackbutton:boolean=false;
      this.spinner.show()
      //  if the quote is expire before the payment Done , we are redirecting user to the login Page from the  expiry error page
      let locationName = location.pathname.split("/");
      let url = locationName[2];
      if(url != "app-expiry")
      {
      this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID)
      this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
      this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
      this.customerUuvid = this.sessionStorage.get(CUSTOMERUUVID);
      this.version = this.sessionStorage.get(VERSION);
      let flows = this.flowdata.filter(flowObj => { return this.currentWorkflow.trim().toLowerCase() == flowObj.flowName.trim().toLowerCase() });
      const token = this.sessionStorage?.get(TOKEN);
      /**
       *
       * Actions of Quote Failed Error Page Back Button
       * 1.Activate last success quote by setting active_status of failed to 0 and active_status of last success as 1
       * 2.Get Last success quote enquiry Id
       * 3.Get Last success Quote last Work flow status like previous and current work flow and update same in Workflow transaction table.
       * 4.Redirect to previous flow.
       */
      if(this.currentWorkflow==="quote_failed"){
        isQuoteFailedBackbutton=true;
        let lastSuccessId=await this.createQuoteService.errorRedirectQuote(this.customerUuvid)
        this.sessionStorage.set(QUOTEENQUIRYID,lastSuccessId.response.quoteEnquiryId);
        this.sessionStorage.set(PREVIOUSWORKFLOW,lastSuccessId.response.lastSuccessWorkFlow.previousWorkFlow);
        this.sessionStorage.set(CURRENTWORKFLOW,lastSuccessId.response.lastSuccessWorkFlow.currentWorkFlow);
        this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID)
        this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
        this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
        await this.updateWorkFlowForFailedBackButton();
        await this.getCustomerLastWorkflow();
        }
        else if(this.currentWorkflow==="payment_failed"){
          this.sessionStorage.set(PREVIOUSWORKFLOW,"selected_product");
          this.sessionStorage.set(CURRENTWORKFLOW,"quote_confirmed");
          this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID)
          this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
          this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
          await this.updateWorkFlow();
          await this.getCustomerLastWorkflow();
          this.route.navigateByUrl(`${this.customerUuvid}/${flows[0].backbuttonRoute}`)
          }
      else{
      await this.updateWorkFlow();
      await this.getCustomerLastWorkflow();
      this.route.navigateByUrl(`${this.customerUuvid}/${flows[0].backbuttonRoute}`)
    }
      }
      else{
        this.customerUuvid = this.sessionStorage.get(CUSTOMERUUVID);
        this.route.navigateByUrl(`/login`)
      }
    } catch (exception) {
    } finally {
      this.spinner.hide()
    }
  }

  async updateWorkFlow() {
    try {
      let workFlowDetail = {
        quoteEnquiryId: this.quoteEnquiryId,
        previousQuoteStatus: this.currentWorkflow, //For back button the flow is interchanged so previousflow=currentFlow ,currentFlow=PreviousFlow
        currentQuoteStatus: this.previousWorkFlow,
        version: this.version
      }
      const response = await this.createQuoteService.updateWorkFlowStatus(workFlowDetail)
    } catch (error) {
    }
  }

  async updateWorkFlowForFailedBackButton() {
    try {
      let workFlowDetail = {
        quoteEnquiryId: this.quoteEnquiryId,
        previousQuoteStatus: this.previousWorkFlow, //For back button the flow is interchanged so previousflow=currentFlow ,currentFlow=PreviousFlow
        currentQuoteStatus: this.currentWorkflow,
        version: this.version
      }
      const response = await this.createQuoteService.updateWorkFlowStatus(workFlowDetail)
    } catch (error) {
    }
  }

  async getCustomerLastWorkflow() {
    try {
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(this.flowdata);
      this.router.navigateByUrl(`${this.customerUuvid}/${getFLows[0].route}`)
    } catch (exception) {

    }
  }


  copy(){
    let pathList = window.location.href.split('/');
    this.clipboardService.copy(`${pathList[2]}/${this.customerUuvid}`);
    this.toaster.success("Link Copied");
  }

  changeProfileDropdownStatus(){
    this.profileDropdownStatus = !this.profileDropdownStatus;
  }
  redirectToResetPassword(){
    this.router.navigate([this.customerUuvid,'reset-password'])
    this.changeProfileDropdownStatus()
  }
}
