
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/create-quote/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageLoaderComponent } from './spinner_css/page-loader/page-loader.component';
import { CreateQuoteModule } from './modules/create-quote/create-quote.module';
import { ErrorComponent } from './modules/error/error/error.component';
import {PaymentSuccessComponent} from './modules/payment-success/payment-success.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { KycComponent } from './modules/auth/kyc/kyc.component';
import { EmailUpdateComponent } from './modules/auth/email-update/email-update.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { ClipboardModule } from 'ngx-clipboard';
import { LoaderV2Component } from 'src/app/spinner_css/loader-v2/loader-v2.component';
import { PaymentSuccessV2Component } from './modules/payment-success-v2/payment-success-v2.component';
import { ExpiryComponent } from './modules/error/expiry/expiry.component';
import { OtpComponent } from './modules/auth/otp/otp.component';
import { NgOtpInputModule } from 'ng-otp-input';
@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    PageLoaderComponent,
    ErrorComponent,
    PaymentSuccessComponent,
    LoginComponent,
    KycComponent,
    EmailUpdateComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PaymentSuccessV2Component,
    ExpiryComponent,
    OtpComponent,
  ],
  imports: [
    BrowserModule,
    ClipboardModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    SharedModule,
    NgxSpinnerModule,
    NgOtpInputModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      timeOut:1500,
    }),
    NgbModule,
    // LandingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
